import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import { GoDot, GoDotFill } from "react-icons/go";
import { useAppContext } from "../../context";
import { Toaster } from "sonner";
import {
  CardsCommands,
  CardsContainer,
  ColorPicker,
  DropZone,
  ModalWindow,
  ToolCommands,
} from "../../components";
import { useScreenSize, useUpdateVisitCounter } from "../../utils";
import style from "./Tool.module.css";

function MatchingTool() {
  const {
    setColormatch,
    images,
    setImages,
    user,
    setUser,
    createNewPaletteModal,
    setCreateNewPaletteModal,
  } = useAppContext();
  const [sortable, setSortable] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexBrightness, setIndexBrightness] = useState([]);
  const [showConfirmRestart, setShowConfirmRestart] = useState(false);
  const [showModalHelp, setShowModalHelp] = useState(false);
  const updateVisitCounter = useUpdateVisitCounter();
  const [selectedColor, setSelectedColor] = useState("");
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setIndex(images.length - 1);
    setIndexBrightness((prevState) => {
      const newBrightnessState = images.map((image, index) => {
        const existingBrightness = prevState.find(
          (item) => item.index === index
        );
        return existingBrightness
          ? existingBrightness
          : { index, brightness: 1 };
      });
      return newBrightnessState;
    });
  }, [images]);

  useEffect(() => {
    if (user) {
      if (user.firstToolVisit) {
        setShowModalHelp(true);
      }
    }
  }, [user]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleRestart = () => {
    setSortable(false);
    setColormatch([]);
    setImages([]);
    setShowConfirmRestart(false);
    setCreateNewPaletteModal(false);
    navigate(`/${i18n.language}/colormatch`);
  };

  const handleShowConfirmRestart = () => {
    setShowConfirmRestart(true);
  };

  const handleCloseModalHelp = () => {
    setShowModalHelp(false);
    setUser((prevState) => ({ ...prevState, firstToolVisit: false }));
    updateVisitCounter.mutate(user.email);
  };

  const goToPrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDeleteImage = () => {
    setImages((prevState) =>
      prevState.filter((item) => item !== images[index])
    );
    setIndexBrightness((prevState) =>
      prevState.filter((item, idx) => idx !== index)
    );
    if (images.length > 1 && index !== 0) {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleBrightnessChange = (value) => {
    if (index !== null) {
      setIndexBrightness((prevState) =>
        prevState.map((item) =>
          item.index === index ? { ...item, brightness: value } : item
        )
      );
    }
  };

  const currentBrightness = () => {
    const brightnessObj = indexBrightness.find((item) => item.index === index);
    return brightnessObj ? brightnessObj.brightness : 1;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "Restarting will clear your current work and palettes. Do you want to continue?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <ToolCommands
          handleRestart={handleRestart}
          handleShowConfirmRestart={handleShowConfirmRestart}
          goToPrev={goToPrev}
          goToNext={goToNext}
          handleDeleteImage={handleDeleteImage}
          handleBrightnessChange={handleBrightnessChange}
          indexBrightness={indexBrightness}
          index={index}
          currentBrightness={currentBrightness}
        />
        <CardsCommands sortable={sortable} setSortable={setSortable} />
        {images.length === 0 ? (
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 5 }}
            className={style.colorPickerCol}
          >
            <DropZone />
          </Col>
        ) : (
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 5 }}
            {...(screenSize >= 1400 && screenSize <= 1565
              ? {}
              : { xxl: { order: 2, span: 4 } })}
            /*  xxl={{ order: 2, span: 4 }} */
            className={style.colorPickerCol}
          >
            <ColorPicker
              brightness={currentBrightness()}
              index={index}
              onSelect={handleSelect}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
            {images.length > 1 && (
              <Col
                xs={12}
                lg={12}
                xl={11}
                xxl={10}
                className={style.imagesDots}
              >
                {images.map((image, i) =>
                  index === i ? (
                    <GoDotFill key={i} />
                  ) : (
                    <GoDot
                      key={i}
                      onClick={() => {
                        setIndex(i);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )
                )}
              </Col>
            )}
          </Col>
        )}
        <Col
          xs={{ order: "last", span: 12 }}
          md={{ order: "last", span: 12 }}
          lg={{ order: "last", span: 6 }}
          xl={{ order: "last", span: 5 }}
          className={style.cardsCol}
        >
          <CardsContainer
            sortable={sortable}
            setSelectedColor={setSelectedColor}
          />
        </Col>
      </Row>
      {/* RESTART */}
      <ModalWindow
        size="md"
        show={showConfirmRestart}
        onHide={() => setShowConfirmRestart(false)}
        closeButton
        title={t("Restart Screen?")}
        body={
          <Container>
            <Row className={style.modalConfirmRestart}>
              <p className={`mb-4`}>
                {t("Restarting will clear your current work and palettes.")}{" "}
                <br />
                {t("Do you want to continue?")}
              </p>
              <div className={style.confirmRestartBtnContainer}>
                <Col>
                  <Button onClick={handleRestart} className={style.actionBtn}>
                    {t("Yes, Restart")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => setShowConfirmRestart(false)}
                    className={style.actionBtn}
                  >
                    {t("Cancel")}
                  </Button>
                </Col>
              </div>
            </Row>
          </Container>
        }
      />

      {/* WELCOME */}
      <ModalWindow
        size="md"
        centered={true}
        helpModal={true}
        show={showModalHelp}
        onHide={() => setShowModalHelp(false)}
        style={{ backgroundColor: "#000000" }}
        /* title={"Restart Screen?"} */
        body={
          <Container className={style.modalHelpContainer}>
            <Row className={style.modalHelpContent}>
              <h3 className="mb-4">{t("How It Works")}</h3>
              <p>{t("1. Upload an image")}</p>
              <p>{t("2. Click or tap on desired color")}</p>
              <p>{t("3. Get the closest balloon match!")}</p>
              <Button
                onClick={handleCloseModalHelp}
                className={`${style.confirmHelpBtn} mt-4`}
              >
                {t("Get Started")}
              </Button>
            </Row>
          </Container>
        }
      />

      {/* CREATE NEW PALETTE */}
      <ModalWindow
        size="md"
        show={createNewPaletteModal}
        onHide={() => setCreateNewPaletteModal(false)}
        closeButton
        title={t("Create New Palette")}
        body={
          <Container>
            <Row className={style.modalConfirmRestart}>
              <p className={`mb-4`}>
                {t("This action will clear your current work and palettes.")}
                <br />
                {t("Do you want to continue?")}
              </p>
              <div className={style.confirmRestartBtnContainer}>
                <Col>
                  <Button onClick={handleRestart} className={style.actionBtn}>
                    {t("Yes, Create New Palette")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => setCreateNewPaletteModal(false)}
                    className={style.actionBtn}
                  >
                    {t("Cancel")}
                  </Button>
                </Col>
              </div>
            </Row>
          </Container>
        }
      />
      <Row>
        <Col className="justify-content-end d-flex p-4">
          <p className={style.versionTxt}>{t('versionTxt')}</p>
        </Col>
      </Row>
      <Toaster richColors position="bottom-center" />
    </Container>
  );
}

export default MatchingTool;
