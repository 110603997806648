import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import poppinsFont from "../../assets/Poppins-Medium.ttf";

Font.register({ family: "Poppins", src: poppinsFont });

const style = StyleSheet.create({
  page: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "14px",
  },
  imageContainer: {
    height: "auto",
    width: "100%",
  },
  imagesContainer: {
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
  },
  images: {
    height: "auto",
    width: "45%",
  },
  image: {
    height: "auto",
    width: "100%",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "30px",
    marginTop: "18px",
    justifyContent: "flex-start",
  },
  cardsContainerPreference: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    /* justifyContent: "space-between", */
  },
  cards: {
    width: "calc(20% - 10px)",
    margin: "10px",
    boxSizing: "border-box",
  },
  cardsPreference: {
    width: "calc(26% - 20px)",
    marginHorizontal: "10px",
    marginBottom: "10px",
    boxSizing: "border-box",
  },
  title: {
    fontFamily: "Poppins",
    textTransform: "uppercase",
    marginTop: "8px",
    fontSize: "8px",
    lineHeight: "1",
    wordBreak: "break-word",
    textAlign: "left",
  },
  titlePreference: {
    fontFamily: "Poppins",
    textTransform: "uppercase",
    marginTop: "8px",
    fontSize: "8px",
    lineHeight: "1",
    wordBreak: "break-word",
    textAlign: "left",
    maxWidth: "80px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "10px",
  },
});

const PDFClient = ({ colors, images, showImage, sharedPalette }) => {
  return (
    <Document>
      <Page style={style.page} wrap={false}>
        <View style={showImage && style.body}>
          {showImage && (
            <View
              style={
                images.length > 1 ? style.imagesContainer : style.imageContainer
              }
            >
              {images.map((img, index) => (
                <Image
                  key={index}
                  src={sharedPalette ? img.imageURL : img}
                  style={images.length > 1 ? style.images : style.image}
                />
              ))}
            </View>
          )}
          <View
            style={
              showImage ? style.cardsContainerPreference : style.cardsContainer
            }
          >
            {colors.length > 0 &&
              colors.map((card, index) => {
                return (
                  <View
                    key={index}
                    style={showImage ? style.cardsPreference : style.cards}
                  >
                    <View
                      style={{
                        width: "100%",
                        height: "10px",
                        backgroundColor: card.colorPicked
                          ? card.colorPicked
                          : card.hexColor.length === 1
                          ? "transparent"
                          : `#${card.hexColor.replace("#", "")}`,
                      }}
                    />
                    <Image
                      src={card.image ? card.image[0].imageURL : card.imageURL}
                      alt="balloon"
                      style={style.image}
                    />
                    <Text
                      style={showImage ? style.titlePreference : style.title}
                    >
                      {card.displayName ? card.displayName : card.name}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>
        <View style={style.footer}>
          <Text
            style={{
              color: "#c8c8c8",
              fontSize: "8px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Powered by Balloon Color Match
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFClient;
