import React, { useState, useEffect } from "react";
import style from "./CanvasWithZoom.module.css";

const ZoomCanvas = ({ zoomRef, ratio, border }) => {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setCursorX(clientX);
      setCursorY(clientY);
    };

    // Agregar el event listener una vez
    window.addEventListener("mousemove", handleMouseMove);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez

  return (
    <div
      className={style.zoomCircle}
      style={{
        position: "fixed", // Asegúrate de que el cursor siga al ratón de manera absoluta
        left: `${cursorX - 30}px`,
        top: `${cursorY - 30}px`,
        width: ratio,
        height: ratio,
        border: `2px solid ${border}`,
        borderRadius: "100%",
        overflow: "hidden",
        zIndex: 10,
      }}
    >
      <canvas
        ref={zoomRef}
        alt="Zoom"
        style={{
          width: "100%", // El canvas ocupa el 100% del div padre
          height: "100%", // El canvas ocupa el 100% del div padre
          borderRadius: "50%",
          border: "solid 1px #292929",
        }}
        width={400} // Establece la resolución interna del canvas
        height={400} // Ajusta según sea necesario
      />
    </div>
  );
};

export default ZoomCanvas;