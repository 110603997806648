import axios from "axios";
import { Alert } from "./index";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../firebase";
import { v4 as uuidv4 } from "uuid";

export const colorMatchFn = async(
  balloonsData,
  isLoading,
  colormatch,
  setColormatch,
  elementSelected,
  options = {}
) => {
  const v1 = true;

  const { insert } = options;

  if (!isLoading && elementSelected) {
    try {
      const response = await axios.post(process.env.REACT_APP_COLORMATCHFN_TEST_URL, {
        balloonsData,
        elementSelected,
        colormatch,
        options,
        v1
      });

      const { closerColor } = response.data;

      if (colormatch.some((item) => closerColor.color === item.color)) {
        return Alert.error("There is already a balloon with this color.");
      }
  
      if (insert || insert === 0) {
        const copyColorMatch = [...colormatch];
        copyColorMatch.splice(insert, 1, closerColor);
        return setColormatch(copyColorMatch);
      }
      setColormatch((prevState) => [...prevState, closerColor]);
    }catch(error){
      console.error('Error fetching color match:', error);
    }
  }
};

export const loadImage = (canvas, image, brightness, callback) => {
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.onload = () => {
   /*  const aspectRatio = img.width / img.height;
    const maxWidth = window.innerWidth <= 576 ? 380 : 500;
    const maxHeight = maxWidth / aspectRatio; */

    // canvas.width = maxWidth;
    canvas.width = img.width;
    // canvas.height = maxHeight;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0, img.width, img.height);
    applyBrightness(ctx, brightness);
    callback && callback();
  };

  if (image) img.src = URL.createObjectURL(image);
};

const applyBrightness = (ctx, brightness) => {
  const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  const data = imageData.data;
  for (let i = 0; i < data.length; i += 4) {
    data[i] = Math.min(255, data[i] * brightness); // Red
    data[i + 1] = Math.min(255, data[i + 1] * brightness); // Green
    data[i + 2] = Math.min(255, data[i + 2] * brightness); // Blue
  }
  ctx.putImageData(imageData, 0, 0);
};

export const drawZoom = (zoomCanvas, originalCanvas, position) => {
  const zoomCtx = zoomCanvas.getContext("2d");

  // Limpiar el canvas de zoom antes de dibujar
  zoomCtx.clearRect(0, 0, zoomCanvas.width, zoomCanvas.height);

  const zoomFactor = 35; // Define el nivel de zoom

  // Ajuste para centrar el zoom en la posición del cursor
  const zoomX = position.x - zoomCanvas.width / (2 * zoomFactor); // Restar la mitad del área visible
  const zoomY = position.y - zoomCanvas.height / (2 * zoomFactor); // Restar la mitad del área visible

  // Dibujar el área ampliada en el canvas de zoom
  zoomCtx.drawImage(
    originalCanvas,
    zoomX,
    zoomY,
    zoomCanvas.width / zoomFactor,
    zoomCanvas.height / zoomFactor,
    0,
    0,
    zoomCanvas.width,
    zoomCanvas.height
  );
};

/* const sortBalloons = (balloons) => {
  //Ordena los globos por categoria
  const categorized = balloons.reduce((acc, item) => {
    if (item.colorCategories) {
      item.colorCategories.forEach((color) => {
        if (!acc[color]) {
          acc[color] = [];
        }
        acc[color].push(item);
      });
    }
    return acc;
  }, {});
  Object.keys(categorized).forEach((category) => {
    categorized[category].forEach();
  });
}; */

const uploadingImage = ({
  image,
  updateTotalProgress,
  collection,
  id,
  name,
}) => {
  const storage = getStorage(app);
  const storageRef = ref(storage, `${collection}/${id}_${name}`);

  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const bytesTransferred = snapshot.bytesTransferred;
        const totalBytesOfCurrentImage = snapshot.totalBytes;
        const imageProgress =
          (bytesTransferred / totalBytesOfCurrentImage) * 100;
        updateTotalProgress(imageProgress, totalBytesOfCurrentImage);
      },
      (error) => {
        console.error("Error al subir la imagen:", error);
        reject(error);
      },
      async () => {
        console.log("Imagen subida correctamente!");
        try {
          const downloadURL = await getDownloadURL(storageRef);
          resolve({ downloadURL, path: storageRef.fullPath });
        } catch (error) {
          console.error("Error obteniendo la URL de descarga:", error);
          reject(error);
        }
      }
    );
  });
};

export const imagesMapToUpload = async ({
  images,
  setProgress,
  collection,
  id,
}) => {
  const totalBytes = images.reduce((acc, image) => acc + image.size, 0); // Tamaño total de las imágenes
  let totalBytesTransferred = 0;

  const updateTotalProgress = (bytesTransferredForImage) => {
    totalBytesTransferred += bytesTransferredForImage;

    const totalProgress = (totalBytesTransferred * 100) / totalBytes;

    setProgress(Math.floor(totalProgress));
  };

  return await Promise.all(
    images.map(async (image) => {
      const { downloadURL, path } = await uploadingImage({
        image,
        updateTotalProgress,
        collection,
        id,
        name: image.name,
      });

      return {
        imageURL: downloadURL,
        imageName: image.name,
        imagePath: path,
      };
    })
  );
};
